const languageSelector = Vue.component('language-selector', {
    template: "#language-selector",

    props: {
        compact: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            activeLanguage: translation.getActiveLanguage(),

            activeLanguages: brand.activeLanguages,

            defaultLanguage: brand.defaultLanguage
        }
    },

    computed: {
        languageOptions() {
            if(this.activeLanguages.length) {
                return this.activeLanguages.map(l => {
                    return { id: l, code: l.split('-')[1].toLowerCase(), text: translation.get(`global_${l}`) }
                })
            }

            return [
                { id: 'en-GB', code: "gb", text: translation.get('global_en-GB') }
            ]
        },

        display() {
            return this.compact && this.languageOptions.length === 1 ? false : true
        }
    },

    methods: {
        /**
         * @param {{id: string, text: string}} option
         */
        formatResults: function (option) {
            let cls = this.languageOptions.filter(el => el.code === option.code)

            if (!cls.length) {
                cls = this.languageOptions.filter(el => el.value === option.code)
            }

            if (cls.length) {
                let flagClass = cls[0].code

                if (this.compact) {
                    return $(`<span><span class="flag-icon flag-icon-${flagClass}"></span></span>`)
                } else {
                    return $(`<span><span class="mr-2 flag-icon flag-icon-${flagClass}"></span> ${option.text}</span>`)
                }
            }
        },

        updateLanguageSettings() {
            $.ajax({
                method: 'post',
                url: `/a/changeLanguage`,
                contentType: 'application/json',
                data: JSON.stringify({
                    language: this.activeLanguage
                }),
            }).done(() => {
                window.location.reload()
            }).fail(() => {
                ShowNotification.fail(translation.get('preferences_save_fail'))
            })
        },
    },

    watch: {
        activeLanguage(v) {
            this.updateLanguageSettings()
        }
    },

    components: {
        vueSelect: VueSelect,
    },
})